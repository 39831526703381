<template>
  <v-container fluid>
    <v-card outlined class="pa-4">
      <v-form
        ref="form"
        class="form"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" sm="3" class="pt-8">
            <label>
              {{$_strings.closePeriod.TYPE_CLOSE_PERIODE}}
            </label>
          </v-col>
          <v-col cols="12" sm="9">
            <v-checkbox
              hide-details
              :label="isManual ? 'Manual' : 'Otomatis'"
              v-model="isCheckobox"
              disabled
            >
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <label for="jedaClosePeriode">
              {{$_strings.closePeriod.JEDA_CLOSE_PERIODE}}
            </label>
          </v-col>
          <v-col cols="12" sm="5">
            <v-text-field
              id="jedaClosePeriode"
              class="body-2"
              v-model="interval"
              :hide-details="hideDetails"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.closePeriod.JEDA_CLOSE_PERIODE)]"
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              required
              dense
              outlined
            />
            <span class="font-italic caption">Note: Total jadi hari kerja</span>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col cols="12" sm="8" class="d-flex justify-end">
            <v-btn color="red" class="white--text mr-3" @click="$router.back()">
              {{$_strings.common.CANCEL}}
            </v-btn>
            <v-btn @click="submit" class="primary">
              {{$_strings.common.SAVE}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>

export default {
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.interval = data.intervalPeriod;
      this.isManual = data.isManual;
    } else {
      this.fetchData();
    }
  },
  data() {
    return {
      hideDetails: true,
      isCheckobox: true,
      isManual: false,
      interval: null,
    };
  },
  methods: {
    async fetchData() {
      this.$root.$loading.show();
      try {
        const result = await this.$_services.closePeriod.closePeriodeDefault();
        this.interval = result.data.intervalPeriod;
        this.isManual = result.data.isManual;
      } finally {
        this.$root.$loading.hide();
      }
    },
    async submit() {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.hideDetails = false;
        return;
      }
      try {
        this.$root.$loading.show();
        await this.$_services.closePeriod.updateClosePeriodeDefault(this.interval);
        this.$dialog.notify.success('Berhasil memperbarui close periode');
        this.$router.replace('/main/close-period');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
